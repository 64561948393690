import ApiService, { ApiError } from 'core/services/api';
import { UploadRequest, UploadResponse } from 'modules/documents/services/DocumentsService';
import Document, { DocumentType } from 'modules/documents/types/Document';
import { DraftInvoice } from 'modules/irp/modules/vehicles/api/Invoice';
import Vehicle, {
	VehicleFields,
	VehicleIncludeFields,
	VehicleSearchFields,
} from 'modules/irp/modules/vehicles/types/Vehicle';
import { Invoice } from 'types/Invoice';
import LookupValue from 'types/LookupValue';
import Supplement, {
	CreateSupplementRequest,
	ListRestrictionsRequest,
	SupplementActionFields,
	SupplementFields,
	SupplementType,
} from 'types/Supplement';
import WeightGroup, { WeightGroupFields } from 'types/WeightGroup';

export interface CreateRestriction {
	supplement: Supplement;
	vehicle?: Vehicle;
}

export default class SupplementsService extends ApiService {
	constructor() {
		const baseUrl = process.env.REACT_APP_CLEARFLEET_API as string;
		super(`${baseUrl}/v1/supplements`);
	}

	// Add a vehicle to a supplement
	async addVehicle(supplementKey: string, vehicleKey: string): Promise<Vehicle> {
		const { vehicle } = await this.POST<{ vehicleKey: string }, { vehicle: Vehicle }>(`/${supplementKey}/vehicles`, {
			vehicleKey,
		});
		return vehicle;
	}

	// Create Supplement
	async create(request: CreateSupplementRequest): Promise<Supplement> {
		const { supplement } = await this.POST<CreateSupplementRequest, { supplement: Supplement }>('', request);
		return supplement;
	}

	// Create a new vehicle and attach it to a supplement
	async createVehicle(supplementKey: string, vehicle: VehicleFields): Promise<Vehicle> {
		const { vehicle: createdVehicle } = await this.POST<{ vehicle: VehicleFields }, { vehicle: Vehicle }>(
			`/${supplementKey}/vehicles`,
			{ vehicle },
		);
		return createdVehicle;
	}

	// Create a weight group on the supplement
	async createWeightGroup(key: string, weightGroup: WeightGroupFields): Promise<void> {
		return this.POST(`/${key}/weightGroups`, { weightGroup });
	}

	async getBySupplementKey<T extends SupplementType>(supplementKey: string): Promise<Supplement<T>> {
		const { supplement } = await this.GET<{ supplement: Supplement<T> }>(`/${supplementKey}`);

		return supplement;
	}

	async getDraftInvoice(supplementKey: string): Promise<DraftInvoice> {
		const path = `/${supplementKey}/draftInvoice`;
		const { draftInvoice } = await this.GET<{ draftInvoice: DraftInvoice }>(path);
		return draftInvoice;
	}

	async getElectronicDeliveryMethods(): Promise<LookupValue[]> {
		const path = `/config/electronicDeliveryMethods`;
		const { methods } = await this.GET<{ methods: LookupValue[] }>(path);

		return methods;
	}

	async listCredentialDeliveryMethods(supplementKey: string): Promise<LookupValue[]> {
		const path = `/${supplementKey}/credentialDeliveryMethods`;
		const { credentialDeliveryMethods } = await this.GET<{ credentialDeliveryMethods: LookupValue[] }>(path);
		return credentialDeliveryMethods;
	}

	// List restrictions preventing the creation of a supplement
	async listRestrictions(request: ListRestrictionsRequest): Promise<CreateRestriction[]> {
		const { restrictions } = await this.POST<ListRestrictionsRequest, { restrictions: CreateRestriction[] }>(
			'/restrictions',
			request,
		);
		return restrictions;
	}

	// List all vehicles attached to a supplement, paginated
	private async listVehicles(
		supplementKey: string,
		includes?: VehicleIncludeFields,
		pagination?: { limit: number; offset?: number },
	): Promise<Vehicle[]> {
		const { vehicles } = await this.GET<{ vehicles: Vehicle[] }>(`/${supplementKey}/vehicles`, undefined, {
			params: {
				...pagination,
				include: includes && Object.keys(includes).length > 0 ? Object.keys(includes).join(',') : undefined,
			},
		});

		return vehicles;
	}

	// List all vehicles attached to a supplement
	// NOTE: This endpoint is paginated, this method will fast load
	//       the first page size (usually 10 vehicles) and then load
	//       the rest of the vehicles in the supplement in the background
	//       A setLoading function can be passed to set the loading state
	//       once the first page is loaded
	async listAllVehicles(
		supplementKey: string,
		includes?: VehicleIncludeFields,
		opts?: { onPage?: (pageNumber: number, vehicles: Vehicle[]) => void },
	): Promise<Vehicle[]> {
		const initialPageSize = 10;
		const pageSize = 500;
		const vehicles: Vehicle[] = [];

		const loadPage = async (page: number, limit: number, offset: number) => {
			const resp = await this.listVehicles(supplementKey, includes, { limit, offset });
			vehicles.push(...resp);

			// If we have an onPage callback, call it with the current page and vehicles
			// NOTE: Vehicles are appended to the array, so the callback will be called
			//       with an increasing number of vehicles each time, as each page is loaded
			if (opts && opts.onPage) opts.onPage(page + 1, vehicles);

			return resp.length;
		};

		// Load all pages of vehicles
		const loadAllPages = async (page: number): Promise<number> => {
			// Fast load the first page
			const limit = page === 0 ? initialPageSize : pageSize;
			const offset = page === 0 ? 0 : (page - 1) * pageSize + initialPageSize;

			const count = await loadPage(page, limit, offset);
			if (count === limit) return loadAllPages(page + 1);
			return 0;
		};

		await loadAllPages(0);
		return vehicles;
	}

	// List all weight groups on the supplement
	async listWeightGroups(supplementKey: string, weightGroupType?: number): Promise<WeightGroup[]> {
		const { weightGroups } = await this.GET<{ weightGroups: WeightGroup[] }>(
			`/${supplementKey}/weightGroups`,
			undefined,
			{
				params: { weightGroupType },
			},
		);
		return weightGroups;
	}

	// Search for vehicles that can be attached to a supplement
	async searchVehicles(
		supplementKey: string,
		fields: SupplementsSearchVehiclesRequest,
		includes?: VehicleIncludeFields,
	): Promise<Vehicle[]> {
		const { vehicles } = await this.GET<{ vehicles: Vehicle[] }>(`/${supplementKey}/vehicles`, undefined, {
			params: {
				eligible: true,
				...fields,
				include: includes && Object.keys(includes).length > 0 ? Object.keys(includes).join(',') : undefined,
			},
		});
		return vehicles || [];
	}

	// Transfer a new vehicle and attach it to a supplement
	async transferVehicle(supplementKey: string, transferVehicleKey: string, vehicle: VehicleFields): Promise<Vehicle> {
		const { vehicle: createdVehicle } = await this.POST<
			{ vehicle: VehicleFields; transferVehicleKey: string },
			{ vehicle: Vehicle }
		>(`/${supplementKey}/vehicles`, { vehicle, transferVehicleKey });
		return createdVehicle;
	}

	async triggerAction(supplementKey: string, supplement: SupplementActionFields): Promise<TriggerActionResponse> {
		const resp = await this.PATCH<SupplementActionFields, TriggerActionResponse>(`/${supplementKey}`, supplement);
		return resp;
	}

	async update(supplementKey: string, supplement: SupplementFields): Promise<Supplement> {
		const response = await this.PUT<{ supplement: SupplementFields }, { supplement: Supplement }>(`/${supplementKey}`, {
			supplement,
		});
		return response.supplement;
	}

	// Update a vehicle attached to a supplement
	async updateVehicle(supplementKey: string, vehicleKey: string, vehicle: VehicleFields): Promise<Vehicle> {
		const { vehicle: vehicleResp } = await this.PUT<{ vehicle: VehicleFields }, { vehicle: Vehicle }>(
			`/${supplementKey}/vehicles/${vehicleKey}`,
			{ vehicle },
		);
		return vehicleResp;
	}

	// Validate one or more vehicle fields individually
	// This method is used for early erroring of form fields
	async validateVehicle(
		supplementKey: string,
		vehicleKey: string,
		vehicle: VehicleFields,
		external?: ValidateVehicleExternal,
	): Promise<ApiError[]> {
		const path = vehicleKey
			? `/${supplementKey}/vehicles/${vehicleKey}/validate`
			: `/${supplementKey}/vehicles/validate`;
		const { errors } = await this.POST<ValidateVehicleRequest, { errors: null | ApiError[] }>(path, {
			vehicle,
			external,
		});
		return errors || [];
	}

	// List documents attached to a supplement
	// TODO: Add pagination to this if necessary
	async listDocuments(supplementKey: string): Promise<Document[]> {
		const limit = 100;
		const { documents, total } = await this.GET<{ documents: Document[]; total: number }>(
			`/${supplementKey}/documents`,
			undefined,
			{
				params: {
					limit,
				},
			},
		);
		// If there are more than 100 documents (default page size) attached to the supplement, log a warning
		if (total > limit) {
			// eslint-disable-next-line no-console
			console.warn(
				`More than ${limit} documents attached to supplement, pagination should be implemented`,
				supplementKey,
			);
		}
		return documents;
	}

	// Upload a document to a supplement
	async uploadDocument(supplementKey: string, documentType: DocumentType, file: File): Promise<UploadResponse> {
		const path = `/${supplementKey}/documents`;
		const req: UploadRequest = {
			document: {
				name: file.name,
				contentType: file.type,
				size: file.size,
				documentType,
			},
		};

		const resp = await this.POST<UploadRequest, UploadResponse>(path, req);
		return resp;
	}
}

export type SupplementsSearchVehiclesRequest = Pick<VehicleSearchFields, 'vin' | 'titleNumber' | 'unitNumber'> & {
	weightGroupType?: number;
};

export type TriggerActionResponse = {
	supplement: Supplement;
	invoice?: Invoice;
};

export interface ValidateVehicleRequest {
	vehicle: VehicleFields;
	external?: ValidateVehicleExternal;
}

export interface ValidateVehicleExternal {
	// Validate against PRISM
	prism?: boolean;
}
