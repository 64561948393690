import CheckIcon from '@mui/icons-material/Check';
import MuiKeyboardArrowDown from '@mui/icons-material/KeyboardArrowDown';
import SearchIcon from '@mui/icons-material/Search';
import { CircularProgress, styled, Tooltip } from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import FormControl from '@mui/material/FormControl';
import Input from '@mui/material/Input';
import MenuItem from '@mui/material/MenuItem';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import Typography from '@mui/material/Typography';
import { GridColDef, GridRowSelectionModel } from '@mui/x-data-grid';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import ActionMenu from 'core/components/ActionMenu';
import StyledDataGrid, { DataGridColumnHeader } from 'core/components/DataGrid';
import { useAPI, useToast } from 'core/hooks';
import { dateFormat, dateFromObject, dayjsFromObject } from 'core/services/intl';
import { Dayjs } from 'dayjs';
import { FormikErrors, setIn, useFormik } from 'formik';
import SupplementsService from 'modules/irp/modules/supplements/api/SupplementsService';
import { SupplementContentSkeleton } from 'modules/irp/modules/supplements/components/SupplementPageContainer';
import AddVehicleDialog from 'modules/irp/modules/supplements/components/dialogs/AddVehicleDialog';
import RemoveVehicleDialog from 'modules/irp/modules/supplements/components/dialogs/RemoveVehicleDialog';
import DeleteVehicleFooter from 'modules/irp/modules/supplements/modules/delete_vehicle/components/DeleteVehicleFooter';
import EditVehiclesDialog from 'modules/irp/modules/supplements/modules/delete_vehicle/components/EditVehicleDialog';
import DeleteVehiclePaths from 'modules/irp/modules/supplements/modules/delete_vehicle/routes/paths';
import { useSupplement } from 'modules/irp/modules/supplements/providers/SupplementProvider';
import { getFeeCalcTooltipError } from 'modules/irp/modules/supplements/types/FCD/FCDUtilities';
import VehiclesService from 'modules/irp/modules/vehicles/api/VehiclesService';
import Vehicle, { VehicleFields } from 'modules/irp/modules/vehicles/types/Vehicle';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useTypedParams } from 'react-router-typesafe-routes/dom';
import Date, { DateValidations, DateValidationSchema, dayjsToDate } from 'types/Date';
import LookupValue, { LookupValueValidationSchema } from 'types/LookupValue';
import * as Yup from 'yup';

export type DeleteDetailsFormRecord = Vehicle & {
	deactivate: {
		date?: Date;
		reason?: LookupValue;
	};
	return?: LookupValue;
};

const StyledButton = styled(Button)({ minWidth: 150 });
export const KeyboardArrowDown = styled(MuiKeyboardArrowDown)(({ theme }) => ({
	fill: theme.palette.primary.main,
}));

const DataGrid = StyledDataGrid<Vehicle>();

export default function DeleteDetailsStep() {
	const { t } = useTranslation(['irp/supplements/delete_vehicle', 'irp/supplements/add_vehicle', 'irp/supplements']);
	const navigate = useNavigate();
	const { openToast } = useToast();

	const vehiclesService = useAPI(VehiclesService);
	const supplementsService = useAPI(SupplementsService);
	const { supplementKey } = useTypedParams(DeleteVehiclePaths.Details);
	const { supplement } = useSupplement();
	const registrationYear = supplement
		? {
				startDate: supplement.fleet.startDate,
				endDate: supplement.fleet.endDate,
			}
		: null;

	const validationSchema = Yup.object().shape({
		deactivate: Yup.object().shape({
			date: DateValidationSchema.default(undefined)
				.required(t('data.validation.required', { ns: 'core' }))
				.test(
					'withinRegistrationYear',
					DateValidations.withinDateRange(registrationYear, t(`data.validation.datepicker.regYear`, { ns: 'core' })),
				),
			reason: Yup.object()
				.shape(LookupValueValidationSchema)
				.test('required', t('data.validation.required', { ns: 'core' }), (v) => !!v && !!v.code),
		}),
		plate: Yup.object().shape({
			return: Yup.object()
				.shape(LookupValueValidationSchema)
				.test('required', t('data.validation.required', { ns: 'core' }), (v) => !!v && !!v.code),
		}),
	});

	const { setValues, ...formik } = useFormik<DeleteDetailsFormRecord[]>({
		initialValues: [],
		validationSchema: Yup.array().of(validationSchema),
		onSubmit: () => navigate(DeleteVehiclePaths.Documentation.buildPath({ supplementKey })),
	});

	// State
	const [loading, setLoading] = useState<boolean>(true);
	const [tableLoading, setTableLoading] = useState<boolean>(false);
	const [searchValue, setSearchValue] = useState<string>('');
	const [deactivateReasons, setDeactivateReasons] = useState<LookupValue[]>([]);
	const [plateReturnOptions, setPlateReturnOptions] = useState<LookupValue[]>([]);
	const [rowsSaving, setRowsSaving] = useState<Record<string, boolean>>({});

	// Dialogs
	const [addVehicleDialogOpen, setAddVehicleDialogOpen] = useState<boolean>(false);
	const [removeDialogVehicle, setRemoveDialogVehicle] = useState<Vehicle | null>(null);

	// Edit Vehicles Dialog
	const [editVehicleDialogOpen, setEditVehicleDialogOpen] = useState<boolean>(false);
	const [selectedVehicleIds, setSelectedVehicleIds] = useState<GridRowSelectionModel>([]);
	const selectedVehicles = formik.values.filter((vehicle) => selectedVehicleIds.includes(vehicle.id));

	const getVehicles = useCallback(async () => {
		const sortVehicles = (vehicles: Vehicle[], errors: FormikErrors<DeleteDetailsFormRecord>[]): Vehicle[] => {
			const indexedVehicles = vehicles.map((vehicle, index) => ({ vehicle, index }));

			indexedVehicles.sort((a, b) => {
				if (errors[a.index] && !errors[b.index]) return -1;
				if (!errors[a.index] && errors[b.index]) return 1;

				return 0;
			});

			return indexedVehicles.map(({ vehicle }) => vehicle);
		};

		const vehiclesResp = await supplementsService.listAllVehicles(supplementKey, undefined, {
			onPage: (_, v) => {
				setValues(v as DeleteDetailsFormRecord[]);
			},
		});
		setTableLoading(false);

		// Set the values and validate
		const errors = await setValues(vehiclesResp as DeleteDetailsFormRecord[], true);

		if (errors) {
			// Push vehicles marked as ready to the bottom of the list
			const sortedVehicles = sortVehicles(vehiclesResp, errors as FormikErrors<DeleteDetailsFormRecord>[]);
			setValues(sortedVehicles as DeleteDetailsFormRecord[]);
		}
	}, [supplementKey, setValues, supplementsService]);

	const loadVehicles = async () => {
		setTableLoading(true);
		await getVehicles();
		setTableLoading(false);
	};

	const load = useCallback(async () => {
		if (!supplement) return Promise.resolve();

		return Promise.all([
			vehiclesService.getDeleteReasons(),
			vehiclesService.getPlateReturnOptions(supplement.accountKey),
			getVehicles(),
		])
			.then(([deleteReasonsResp, plateReturnOptionsResp]) => {
				setDeactivateReasons(deleteReasonsResp);
				setPlateReturnOptions(plateReturnOptionsResp);
			})
			.finally(() => setLoading(false));
	}, [supplement, vehiclesService, getVehicles]);

	const handleRemoveClicked = (vehicle: Vehicle) => {
		setRemoveDialogVehicle(vehicle);
	};

	const handleRemoveVehicleConfirm = async () => {
		if (!removeDialogVehicle) return undefined;

		return vehiclesService.remove(removeDialogVehicle).then(() => {
			loadVehicles();
			setRemoveDialogVehicle(null);

			openToast({
				id: `remove-vehicle-dialog:${removeDialogVehicle.key}`,
				message: t('irp/supplements:dialogs.remove_vehicle.removed'),
				severity: 'success',
			});
		});
	};

	const handleViewClicked = (vehicle: Vehicle) => {
		navigate(DeleteVehiclePaths.Vehicle.buildPath({ supplementKey, vehicleKey: vehicle.key }));
	};

	const updateVehicle = async (vehicleKey: string, field: string, value: unknown) => {
		const idx = formik.values.findIndex((vehicle) => vehicle.key === vehicleKey);
		if (idx === -1) return;

		const errors = await formik.setFieldValue(`[${idx}].${field}`, value, true);
		const data: typeof formik.values = setIn(formik.values, `[${idx}].${field}`, value);

		// Invalid, skip
		if (errors && errors[idx] && Object.keys(errors[idx] || {}).length > 0) return;

		// Run after the formik state has been updated
		const vehicle = formik.values[idx];
		try {
			setRowsSaving((prev) => ({ ...prev, [vehicle.key]: true }));

			const finalData = { ...data[idx], final: true };
			const updateFields = validationSchema.cast(finalData, {
				context: finalData,
				// Final cast to strip unknown fields, this is required to satisfy API minimum requirements
				stripUnknown: true,
			});
			if (!updateFields) return;

			await supplementsService.updateVehicle(supplementKey, vehicle.key, updateFields as VehicleFields);
		} catch (e) {
			// Revert changes
			loadVehicles();
		} finally {
			setRowsSaving((prev) => ({ ...prev, [vehicle.key]: false }));
		}
	};

	const handleNext = async () => {
		const errors = await formik.validateForm();
		if (Object.keys(errors).length > 0) {
			openToast({
				id: 'delete-details-form-errors',
				message: t('irp/supplements:errors.vehicle.ready'),
				severity: 'error',
			});
		}

		// All deactivate dates must share the same month and year
		const deactivateDatesValid = formik.values.every(
			(val) =>
				val.deactivate?.date?.month === formik.values[0].deactivate?.date?.month &&
				val.deactivate?.date?.year === formik.values[0].deactivate?.date?.year,
		);

		if (!deactivateDatesValid) {
			return openToast({
				id: 'delete-details-form-deactivate-date-error',
				message: t('details.errors.deactivate_date'),
				severity: 'error',
			});
		}

		return formik.submitForm();
	};

	const columns: GridColDef<Vehicle>[] = [
		{ headerName: t('vehicle.vin', { ns: 'data' }), field: 'vin', minWidth: 200, flex: 1 },
		{
			headerName: t('vehicle.unitNumber', { ns: 'data' }),
			field: 'unitNumber',
			minWidth: 50,
			flex: 1,
		},
		{
			headerName: t('vehicle.title.number', { ns: 'data' }),
			field: 'title.number',
			minWidth: 125,
			flex: 1,
			valueGetter: ({ row }) => row.title.number,
			renderCell: ({ row }) => <span>{row.title.number}</span>,
		},
		{
			renderHeader: () => (
				<DataGridColumnHeader
					label={t('vehicle.deactivateDate', { ns: 'data' })}
					tooltip={t('details.tooltips.delete_date')}
				/>
			),
			field: 'deactivateDate',
			minWidth: 160,
			flex: 1,
			renderCell: ({ row }) => {
				const index = formik.values.findIndex((vehicle) => vehicle.id === row.id);
				const error = getFeeCalcTooltipError(formik.errors[index]);

				return (
					<Tooltip title={error}>
						<Box>
							<DatePicker
								value={row.deactivate?.date ? dayjsFromObject(row.deactivate.date) : null}
								onChange={(value: Dayjs | null) => {
									if (!value || !value.isValid()) return;

									updateVehicle(row.key, 'deactivate.date', dayjsToDate(value));
								}}
								minDate={dayjsFromObject(supplement?.fleet?.startDate)}
								maxDate={dayjsFromObject(supplement?.fleet?.endDate)}
								slotProps={{
									textField: {
										variant: 'standard',
										error:
											!!formik.errors[formik.values.findIndex((vehicle) => vehicle.key === row.key)]?.deactivate?.date,
									},
								}}
							/>
						</Box>
					</Tooltip>
				);
			},
		},
		{
			headerName: t('vehicle.deactivateReason', { ns: 'data' }),
			field: 'deleteReason',
			minWidth: 125,
			flex: 1,
			renderCell: ({ row }) => (
				<FormControl fullWidth>
					<Select
						variant="standard"
						value={deactivateReasons ? row.deactivate?.reason?.code || '' : ''}
						error={!!formik.errors[formik.values.findIndex((vehicle) => vehicle.key === row.key)]?.deactivate?.reason}
						onChange={(event: SelectChangeEvent<string>) => {
							if (!row.documents) return;
							updateVehicle(
								row.key,
								'deactivate.reason',
								deactivateReasons.find((v) => v.code === event.target.value),
							);
						}}
					>
						{deactivateReasons.map((reason) => (
							<MenuItem key={reason.id} value={reason.code}>
								{reason.displayName}
							</MenuItem>
						))}
					</Select>
				</FormControl>
			),
		},
		{
			headerName: t('vehicle.plate.return', { ns: 'data' }),
			field: 'plateReturn',
			minWidth: 125,
			flex: 1,
			renderCell: ({ row }) => {
				if (plateReturnOptions.length === 1) return <span>{plateReturnOptions[0].displayName}</span>;

				return (
					<FormControl fullWidth>
						<Select
							variant="standard"
							value={plateReturnOptions ? row.plate?.return?.code || '' : ''}
							error={!!formik.errors[formik.values.findIndex((vehicle) => vehicle.key === row.key)]?.plate}
							onChange={(event: SelectChangeEvent<string>) => {
								if (!row.documents) return;
								updateVehicle(
									row.key,
									'plate.return',
									plateReturnOptions.find((v) => v.code === event.target.value),
								);
							}}
						>
							{plateReturnOptions.map((option) => (
								<MenuItem key={option.id} value={option.code}>
									{option.displayName}
								</MenuItem>
							))}
						</Select>
					</FormControl>
				);
			},
		},
		{
			headerName: t('data.ready', { ns: 'core' }),
			field: 'ready',
			headerAlign: 'center',
			align: 'center',
			maxWidth: 75,
			flex: 1,
			renderCell: ({ row }) => {
				const index = formik.values.findIndex((vehicle) => vehicle.id === row.id);

				// Loading state
				if (rowsSaving[row.key]) return <CircularProgress size={24} />;

				// Valid state
				if (!formik.errors[index]) return <CheckIcon color="success" />;

				// Error state
				return <span>&mdash;</span>;
			},
		},
		{
			headerName: t('data.actions', { ns: 'core' }),
			field: 'action',
			headerAlign: 'center',
			sortable: false,
			align: 'center',
			maxWidth: 75,
			flex: 1,
			renderCell: ({ row }) => (
				<ActionMenu
					options={[
						{ id: 'view', label: t('buttons.view', { ns: 'core' }) },
						{ id: 'remove', label: t('buttons.remove', { ns: 'core' }) },
					]}
					onClick={({ id }) => {
						switch (id) {
							case 'view':
								handleViewClicked(row);
								break;
							case 'remove':
								handleRemoveClicked(row);
								break;
							default:
								break;
						}
					}}
				/>
			),
		},
	];

	// Compute the filtered results based on the filter value
	const filteredVehicles = formik.values.filter((vehicle) => {
		const v = searchValue.toLowerCase();
		return (
			vehicle.vin.toLowerCase().includes(v) ||
			vehicle.unitNumber.toLowerCase().includes(v) ||
			vehicle.title.number.toLowerCase().includes(v) ||
			dateFormat(dateFromObject(vehicle.deactivate.date)).includes(v) ||
			vehicle.deactivate.reason?.displayName.toLowerCase().includes(v) ||
			vehicle.plate?.return?.displayName.toLowerCase().includes(v)
		);
	});

	// Initial load
	useEffect(() => {
		load();
	}, [load, supplementKey]);

	// If there is only one plate return option, set it for all vehicles
	useEffect(() => {
		if (!plateReturnOptions || !formik.values || !formik.values.length) return;
		if (plateReturnOptions.length === 1) {
			formik.values.forEach((vehicle) => {
				if (!vehicle.plate || vehicle.plate?.return?.code === plateReturnOptions[0].code) return;
				updateVehicle(vehicle.key, 'plate.return', plateReturnOptions[0]);
			});
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [plateReturnOptions]);

	if (loading) return SupplementContentSkeleton;

	return (
		<>
			<form name="deleteDetailsForm" noValidate onSubmit={formik.handleSubmit}>
				<Box display="flex" flexDirection="column" rowGap={2}>
					<Card>
						<CardContent>
							<Box display="flex" flexDirection="column" rowGap={1}>
								<Typography variant="h3">{t('details.title')}</Typography>
								<Typography>{t('details.instruction')}</Typography>
							</Box>
							<Box display="flex" justifyContent="flex-end">
								<Input
									startAdornment={<SearchIcon color="primary" />}
									placeholder={`${t('buttons.search', { ns: 'core' })}...`}
									value={searchValue}
									onChange={(e) => setSearchValue(e.currentTarget.value)}
								/>
							</Box>
							<Box mt={2} mb={2}>
								<DataGrid
									className="striped"
									columns={columns}
									rows={filteredVehicles}
									loading={tableLoading}
									checkboxSelection
									rowSelectionModel={selectedVehicleIds}
									onRowSelectionModelChange={setSelectedVehicleIds}
									disableRowSelectionOnClick
								/>
							</Box>
							<Box display="flex" columnGap={1}>
								<StyledButton variant="outlined" onClick={() => setAddVehicleDialogOpen(true)}>
									{t('irp/supplements:dialogs.add_vehicle.title')}
								</StyledButton>
								{selectedVehicleIds.length > 0 && (
									<StyledButton variant="outlined" onClick={() => setEditVehicleDialogOpen(true)}>
										{t('irp/supplements:dialogs.edit_vehicle.title', { count: selectedVehicleIds.length })}
									</StyledButton>
								)}
							</Box>
						</CardContent>
					</Card>
					<DeleteVehicleFooter
						nextLabel={t('buttons.next', { ns: 'core' })}
						onNext={handleNext}
						nextDisabled={Object.values(rowsSaving).find((v) => v === true)}
					/>
				</Box>
			</form>

			<AddVehicleDialog
				supplementKey={supplementKey}
				isOpen={addVehicleDialogOpen}
				setIsOpen={setAddVehicleDialogOpen}
				onVehiclesAdded={() => {
					setAddVehicleDialogOpen(false);
					loadVehicles();
				}}
			/>

			<EditVehiclesDialog
				vehicles={selectedVehicles}
				deactivateReasons={deactivateReasons}
				plateReturnOptions={plateReturnOptions}
				isOpen={editVehicleDialogOpen}
				setIsOpen={setEditVehicleDialogOpen}
				onVehiclesEdited={() => {
					setEditVehicleDialogOpen(false);
					setSelectedVehicleIds([]);
					loadVehicles();
				}}
			/>

			<RemoveVehicleDialog
				vehicle={removeDialogVehicle}
				isOpen={!!removeDialogVehicle}
				setIsOpen={() => {
					setRemoveDialogVehicle(null);
				}}
				onConfirm={handleRemoveVehicleConfirm}
			/>
		</>
	);
}
